import style from './svgJs.module.scss'

const GamesSvg = ({ active = true, onlyWhite = false }) => {
    return (
        <svg className={style.fillNone} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259.25 170.26">
            <path className={active ? style.strokeActive : onlyWhite ? style.onlyWhiteStroke : style.stroke} strokeMiterlimit={10} strokeWidth={11} style={{transition: "all ease 0.2s"}}
                  d="M129.63,118.24c33.13-.29,51.79,17.9,51.79,17.9,14.47,12.87,25.99,21.14,34.94,24.97,17.2,7.35,24.83,1.35,24.83,1.35,19.17-9.88,14.18-74.54-.1-107.61C227.89,24.28,211.15-.33,183.37,6.71c-15.44,3.91-30.31,6.83-40.49,8.65-8.77,1.57-17.74,1.57-26.52,0-10.17-1.82-25.05-4.74-40.49-8.65C48.1-.33,31.37,24.28,18.17,54.84c-14.28,33.07-19.27,97.73-.1,107.61,0,0,7.63,6,24.83-1.35,8.96-3.83,20.47-12.1,34.94-24.97,0,0,18.66-18.2,51.79-17.9Z" />
            <path className={active ? style.strokeActive : onlyWhite ? style.onlyWhiteStroke : style.stroke} strokeMiterlimit={10} strokeWidth={13} style={{transition: "all ease 0.2s"}}
                  d="M85.47,57.32l3.73,1c9.32,2.51,9.32,15.73,0,18.24l-3.73,1c-2.85,.77-5.08,3-5.85,5.85l-1,3.73c-2.51,9.32-15.73,9.32-18.24,0l-1-3.73c-.77-2.85-3-5.08-5.85-5.85l-3.73-1c-9.32-2.51-9.32-15.73,0-18.24l3.73-1c2.85-.77,5.08-3,5.85-5.85l1-3.73c2.51-9.32,15.73-9.32,18.24,0l1,3.73c.77,2.85,3,5.08,5.85,5.85Z" />
            <circle className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill} cx="185.45" cy="44.67" r="11.5" style={{transition: "all ease 0.2s"}} />
            <circle className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill} cx="185.45" cy="90.58" r="11.5" style={{transition: "all ease 0.2s"}} />
            <circle className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill} cx="208.41" cy="67.44" r="11.5" style={{transition: "all ease 0.2s"}} />
            <circle className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill} cx="162.49" cy="67.44" r="11.5" style={{transition: "all ease 0.2s"}}  />
        </svg>
    )
}

export default GamesSvg
