import {makeObservable, observable, action, computed} from 'mobx'
import {ucraftStore} from './ucraftStore'
import {unityStore} from './unityStore'
import {unityLayoutStore} from './unityLayoutStore'
import apiService from '../services/apiServices'
import {cacheService} from '../services/cacheService'
import {participantsStore} from './participantsStore'
import {mediaStore} from './mediaStore'
import {isMobile} from "react-device-detect";
import {authStore} from "./authStore";

const { setEcommerceDomain, setEcommerceProductId } = ucraftStore
const { setHooryToken, setRoomToken, returnRoomToken, setIsActiveMobile } = unityStore
const { setIsPrivate, setIsCreator, setIsDraft, setIsMyRoom } = unityLayoutStore
const { setBlockedUsers, setAdminUsers, setRoomCreatorId, isModerator } = participantsStore
const { setRoomCreator } = mediaStore

class RoomStore {
  likesNum = 0
  isLiked = false
  roomName = ''
  roomBackground = ''
  loadingPercentage = 0
  ticketPopupId = 0
  ticketRoomId = 0
  eventMinutes = 0
  roomMember = false
  timerActive = false
  timeLeft = {}
  isInvalidTicketPopup = false
  creatorName = ''
  needNavigate = false
  showGuestPopup = false
  showGuestSignInPopup = false
  joinUserRingtone = false
  roomType = null
  ticketBuyingUrl = ""
  userBots = []
  chatId = ""

  constructor() {
    makeObservable(this, {
      likesNum: observable,
      isLiked: observable,
      roomName: observable,
      roomBackground: observable,
      loadingPercentage: observable,
      ticketPopupId: observable,
      eventMinutes: observable,
      roomMember: observable,
      timeLeft: observable,
      timerActive: observable,
      isInvalidTicketPopup: observable,
      creatorName: observable,
      needNavigate: observable,
      showGuestPopup: observable,
      showGuestSignInPopup: observable,
      joinUserRingtone: observable,
      ticketBuyingUrl: observable,
      ticketRoomId: observable,
      userBots: observable,
      chatId: observable,
      roomType: observable,
      userIsBot: computed,
      setNumberLikes: action.bound,
      setIsLiked: action.bound,
      setRoomName: action.bound,
      setRoomBackgroundImg: action.bound,
      clearRoomData: action.bound,
      setLoadingPercentage: action.bound,
      setTicketPopupId: action.bound,
      setTimeLeft: action.bound,
      openInvalidTicketPopup: action.bound,
      getRoomData: action.bound,
      setShowGuestPopup: action.bound,
      setShowGuestSignInPopup: action.bound,
      handleUserBot: action.bound,
      setChatId: action.bound,
    })
  }

  setNumberLikes(num) {
    this.likesNum = num
  }

  setIsLiked(bool) {
    this.isLiked = bool
  }

  setRoomName(name) {
    this.roomName = name
  }

  setRoomBackgroundImg(url) {
    this.roomBackground = url
  }

  setLoadingPercentage(num) {
    this.loadingPercentage = num
  }

  setTimeLeft(obj, roomId) {
    this.timeLeft = {
      ...this.timeLeft,
      [roomId]: obj
    }
    this.timerActive = true
  }

  setTicketPopupId(roomId, num, ticketBuyingUrl, minutes = 0, isRoomMember = false, needNavigate = false) {
    this.ticketPopupId = num
    this.ticketRoomId = roomId
    this.ticketBuyingUrl = ticketBuyingUrl
    this.eventMinutes = minutes
    this.needNavigate = needNavigate
    this.roomMember = isRoomMember
  }

  openInvalidTicketPopup(bool) {
    this.isInvalidTicketPopup = bool
  }

  setShowGuestPopup(bool) {
    this.showGuestPopup = bool
  }

  setShowGuestSignInPopup(bool) {
    this.showGuestSignInPopup = bool
  }

  setChatId(str) {
    this.chatId = str
  }

  get userIsBot() {
    const result = this.userBots.filter(bot => bot.user.public_id === cacheService.getMyId)[0]
    return {
      isBot: !!result,
      botId: result?.public_id
    }   
  }

  async getRoomData(roomId, currentLocation) {
    if(isMobile) {
      setIsActiveMobile(true)
    }
    let ID = cacheService.getMyId
    const { data: { data } } = await apiService.getRoomByID(roomId)
    this.userBots = data.user_bots || [];
    cacheService.set('CreatorName', data.creator.username)
    this.roomType = data?.room_type;

    if (returnRoomToken() !== data?.hoory_website_token) {
      setHooryToken(data?.hoory_website_token)
      setRoomToken(data?.hoory_website_token)
      window.hoorySDK?.reRun({
        websiteToken: data?.hoory_website_token,
        baseUrl: 'https://app.hoory.com',
      })
    }
    setEcommerceDomain(data.ecommerce_domain)
    setEcommerceProductId(data.ecommerce_id)
    setHooryToken(data?.hoory_website_token)
    setBlockedUsers(data.blacklisted_users)
    setAdminUsers(data.admins)
    setRoomCreatorId(data.creator.public_id)
    this.setChatId(data.chat_id || "")
    this.creatorName = data.creator.username

    this.setRoomName(data.room_name)
    this.setRoomBackgroundImg(data.compressed_image)
    this.setNumberLikes(data.likes_total_count)
    this.setIsLiked(data.is_liked)
    this.joinUserRingtone = data?.user_ringtone

    if (data.creator.public_id === Number(ID)) {
      setIsCreator(true)
      setRoomCreator(true)
      if (data?.room_state === 3) {
        setIsPrivate(true)
      }
    } else {
      setIsCreator(false)
    }
    let isDraft = data?.room_state === 2
    setIsDraft(isDraft)

    let isMyRoom = data?.room_type === 6 && data.creator.public_id === ID
    setIsMyRoom(isMyRoom)
    if(!!authStore.gender || !authStore.gender && cacheService.isGuest) {
      if (data?.event_data && cacheService.isGuest) {
        this.setShowGuestPopup(true)
        cacheService.set('AlternateGuest', currentLocation.pathname.split('/dashboard/')[1])
        unityStore.setLoadUnity(false)
      } else if (data?.event_data && !data?.event_data?.event_member && data?.creator.public_id !== ID && !isModerator()) {
        this.setTicketPopupId(currentLocation.pathname.split('/dashboard/')[1], data.event_data.room_event_id, data.event_data.ticket_buying_url, 0, false, true)
        unityStore.setLoadUnity(false)
      } else if (data?.event_data && data?.event_data?.event_member && data.event_data?.event_minutes && data.creator.public_id !== ID && !isModerator()) {
        this.setTicketPopupId(currentLocation.pathname.split('/dashboard/')[1], data.event_data.room_event_id, data.event_data.ticket_buying_url, data.event_data.event_minutes, data.event_data.event_member, true)
        unityStore.setLoadUnity(false)
      } else {
        if (!isMobile) {
          unityStore.setLoadUnity(true)
        }
      }
    }
    return data
  }

  async handleUserBot(){
    const {isBot, botId} = this.userIsBot;
    const data = {
      "room_id": cacheService.getRoomId,
      "nft_id": Math.floor((Math.random() * 100000) + 1),
      "previews_owner_wallet_address": "fsdf1581fwefwefw5ef18wefwef1w8efwefwef18"
    }

    if(isBot){
      await apiService.removeRoomBot(botId)
      const newState = this.userBots.filter(bot => bot.user.public_id !== cacheService.getMyId);
      this.userBots = newState;
    }else{
      const {data : {data:botData}} = await apiService.createRoomBot(data)
      this.userBots = [...this.userBots, botData]
    }
  }

  clearRoomData() {
    this.likesNum = 0
    this.isLiked = false
    this.roomName = ''
    this.roomBackground = ''
  }
}

export const roomStore = new RoomStore()
