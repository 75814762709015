import {useMemo, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router-dom";

import {authStore} from "../../store/authStore";
import {translationStore} from "../../store/translationStore";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import apiService from "../../services/apiServices";
import { cacheService } from "../../services/cacheService";

import { selectGenderList } from "./constants";
import {Languages} from "../../constants";

import {useNavigateTo} from "../../helpers/navigateTo";
import {tr} from "../../helpers/translations/tr";

import CustomSelect from "../AddAiBot/CustomSelect/CustomSelect";
import Calendar from "../Calendar/Calendar";
import BlurredBackgroundPopup from "../Popups/PopupsWrappers/BlurredBackgroundPopup";
import {ReactComponent as SelectIcon} from "../../assets/icons/select_icon.svg";

import styles from "./style.module.scss";

export const UserInfoPopup = observer(({setShowUserDataPopup})=> {
    const {gender, setGender, birthDate, setBirthDate} = authStore;
    const {activeLanguage} = translationStore;
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const ref = useRef();
    const {navigateTo} = useNavigateTo();
    const location = useLocation();

    useOnClickOutside(ref, () =>  setOpened(false))

    const submitHandler = async ()=> {
        setLoading(true)
        await apiService.setUserNameById(cacheService.getMyId, {gender: gender.key, day_of_dirth: `${birthDate.year}-${birthDate.month + 1}-${birthDate.day}`});
        setLoading(false)
        if(!!Number(location.pathname.split("dashboard/").pop())) {
            navigateTo(location.pathname, {}, true);
        }
        setShowUserDataPopup(false);
    }

    const inputValue = useMemo(()=> {
        if(!birthDate) return "Select Date of Birth"
        return `${birthDate.day} - ${new Date(0, birthDate.month).toLocaleString(Languages[activeLanguage].locale, { month: 'short' })} - ${birthDate.year}`
    }, [birthDate]);

    return (
        <BlurredBackgroundPopup className={styles.popipContainer} closable={false}>
            <div className={styles.UserInfoPopup}>
                <div className={styles.content}>
                    <div className={styles.popup_header}>
                        <div className={styles.title}>{tr("user_info")}</div>
                        <div className={styles.description}>{tr("user_info_desc")}</div>
                    </div>
                    <div className={styles.title}>{tr("gender")}</div>
                    <CustomSelect
                        placeholder={"Select gender"}
                        genderHandler={setGender}
                        selectList={selectGenderList}
                        selectedItem={gender}
                    />
                    <div className={styles.title}>{tr("date_of_birth")}</div>
                    <div ref={ref}>
                        <div className={styles.selectDate} onClick={()=>setOpened(!opened)}>
                            <div className={styles.dateInput}>
                                {inputValue}
                            </div>
                            <SelectIcon className={`${styles.icon} ${opened ? styles.open : ""}`}/>
                        </div>
                        {
                            opened && (
                                <div className={styles.calendar_container}>
                                    <Calendar selectedDateHandler={setBirthDate} />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={styles.footer}>
                    <button className={`${styles.submitButton}`} disabled={!birthDate && !gender} onClick={submitHandler}>
                        {loading ? tr("loading") : tr("submit")}
                    </button>
                </div>
            </div>
        </BlurredBackgroundPopup>
    )
})