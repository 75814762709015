import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {buyCryptoStore} from "../store/buyCryptoStore";
import {authStore} from "../store/authStore";
import {loadingStore} from "../store/loadingStore";
import {roomStore} from "../store/roomStore";
import {translationStore} from "../store/translationStore";
import {unityStore} from "../store/unityStore";
// import {unityLayoutStore} from "../store/unityLayoutStore";

import apiService from "../services/apiServices";
import {cacheService} from "../services/cacheService";

import {clearCache} from "../helpers/clearCache";
import {useNavigateTo} from "../helpers/navigateTo";

import useQuery from "../hooks/useQuery";

import {Languages} from "../constants";

import scene from "../ui/assets/guest.glb";


export const useRoutionAuth = () => {
    const [authState, setAuthState] = useState({
        isReady: false,
    })
    const {getRoomData} = roomStore;
    const {toggleUnityLoading} = loadingStore;
    // const {setUserData, setShowPassPopup} = unityLayoutStore
    const {setUserBalance} = buyCryptoStore;
    const {setAvatarUrl} = unityStore;
    const {
        setNickname,
        setEmail,
        setHooryValue,
        setProvider,
        setAvatarImage,
        setShowUserDataPopup,
        setGender,
        setBirthDate
    } = authStore;

    const query = useQuery();
    const {navigateTo} = useNavigateTo();
    const currentLocation = useLocation();
    const getUser = () => apiService.getCurrentUser();

    const Id = cacheService.get('Id');
    const {activeLanguage} = translationStore;

    useEffect(() => {
        const init = async () => {
            try {
                const res = await checkToken()
                setAuthState(prev => ({...prev, isReady: res}))
                if (currentLocation.pathname.split(`/${Languages[activeLanguage].value}`)[1] === "/" && res) {
                    navigateTo("/dashboard")
                }
            } catch (error) {
                console.log(error);
                if (currentLocation.pathname.split(`/${Languages[activeLanguage].value}`)[1] === "/dashboard") {
                    clearCache();
                    navigateTo("/")
                }
            }
        }
        if (!currentLocation.pathname.split("dashboard/")[1]) {
            init()
        }
    }, [])

    const getUserData = async () => {
        const checkNullish = Id ?? false
        try {
            if (checkNullish) {
                const {
                    data: {
                        data: {
                            username,
                            email,
                            login,
                            hoory_website_token,
                            auth_provider,
                            avatar_image_url,
                            avatar_url,
                            user_balance,
                            gender,
                            day_of_dirth
                        }
                    }
                } = await getUser();
                setUserBalance(user_balance)
                setNickname(username);
                setEmail(login || email);
                const roomId = currentLocation.pathname.split("dashboard/").pop();
                if (!gender && !day_of_dirth && roomId === "495259808") {
                    setGender(1);
                    setBirthDate("2000-01-01");
                } else {
                    setGender(gender);
                    setBirthDate(day_of_dirth);
                    if (!gender && !cacheService.isGuest) setShowUserDataPopup(true);
                }
                setHooryValue(hoory_website_token);
                setAvatarImage(avatar_image_url.replace("camera=fullbody", "camera=portrait&size=80"));
                setAvatarUrl(avatar_url || scene)
                setProvider(auth_provider)
            }
        } catch (e) {
            console.warn('ERROR: ', e.message);
        }
    }

    const checkToken = useCallback(async () => {
        const token = cacheService.getAuthToken()
        if (!token) {
            if (currentLocation.pathname.split(`/${Languages[activeLanguage].value}`)[1] === "/dashboard") {
                clearCache();
                return navigateTo("/")
            }
            return false
        }
        try {
            const res = await apiService.getCheckToken();
            setAuthState(prev => ({...prev, ...res?.data?.data}));
            await getUserData();
            return true
        } catch (error) {
            console.log("ERROR: ", error)
            if (currentLocation.pathname.split(`/${Languages[activeLanguage].value}`)[1] === "/dashboard") {
                clearCache();
                return navigateTo("/")
            }
            return false
        }
    }, [])

    const handlerGetRoomData = async (id, currentLocation, navigateTo) => {
        try {
            toggleUnityLoading(true);
            if (query.partner_id && query.jwe_token) {
                let userInfo = await apiService.getUserAuth(query.partner_id, query.jwe_token)
                if (userInfo?.data.success) {
                    // if (userInfo?.data?.data.is_registered) {
                    authorization(userInfo?.data?.data)
                    await getRoomData(id, currentLocation, navigateTo)
                    cacheService.set("RoomId", id)
                    // } else {
                    //     setUserData({
                    //         ...userInfo?.data?.data, ...{
                    //             id,
                    //             currentLocation,
                    //             navigateTo,
                    //             auth_token: query.auth_token,
                    //             partner_id: query.partner_id
                    //         }
                    //     })
                    //     setShowPassPopup(true)
                    // }
                } else {
                    navigateTo(`/guest/${id}`);
                }
            } else {
                const isRedy = await checkToken();
                if (isRedy) {
                    await getRoomData(id, currentLocation, navigateTo)
                } else {
                    const data = await apiService.getRoomState(id)
                    if (data.data.data.state === 3) {
                        window.location.assign("/")
                        navigateTo('/');
                    } else {
                        navigateTo(`/guest/${id}`);
                    }
                }
            }
            toggleUnityLoading(false);
        } catch (error) {
            toggleUnityLoading(false);
        }
    }

    const authorization = (data) => {
        const roomId = currentLocation.pathname.split("dashboard/").pop();
        clearCache();
        cacheService.set("is_fastex_staff_user", data.is_fastex_staff_user);
        cacheService.set("isSuperuser", data.is_superuser);
        cacheService.set("Authorization", `Token ${data.token}`);
        cacheService.set("Id", data.public_id);
        cacheService.set("UserType", "user");
        cacheService.set("auth_token", query.auth_token);
        cacheService.set("partner_id", query.partner_id)
        setAuthState(prev => ({...prev, isReady: true}))
        navigateTo(`/dashboard/${roomId}`, {}, true);
    }

    return {
        state: authState,
        checkToken,
        handlerGetRoomData,
        authorization
    }
}