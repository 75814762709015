import {observer} from "mobx-react-lite";
import {useParams, Outlet, useNavigate, useLocation} from "react-router-dom";
import {useEffect} from "react";

import {translationStore} from "../../store/translationStore.js";

import {Languages} from "../../constants";
import {useNavigateTo} from "../../helpers/navigateTo";

const LanguageWrapper = observer(() => {
    const {lang} = useParams();
    const {navigateTo} = useNavigateTo();
    const location = useLocation();
    const {setActiveLanguage, activeLanguage} = translationStore;

    useEffect(() => {
        if(location.search && (location.search.startsWith("?lang=") || location.search.startsWith("?language_code="))) {
            navigateTo(location.pathname.replace(location.pathname, (location.search.split("?lang=")[1] || location.search.split("?language_code=")[1]) + location.pathname ), {replace: true})
        } else {
            if (lang) {
                const matchingLanguage = Object.values(Languages).find(
                    (language) => language.value === lang
                );
                setActiveLanguage(matchingLanguage || Languages[activeLanguage]);
                if (!matchingLanguage) {
                    navigateTo(location.pathname.replace(lang, Languages[activeLanguage].value), {replace: true})
                }
            } else {
                navigateTo(location.pathname.replace((location.search.startsWith("?lang=") || location.search.startsWith("?language_code=")) ? "" : location.search , Languages[activeLanguage].value), {replace: true})
            }
        }
    }, [lang]);

    return <Outlet />
})

export default LanguageWrapper