import React, {useEffect, useMemo} from 'react';

import {tr} from "../../helpers/translations/tr";

import eyeOpen from "../assets/icons/eye_open.svg";
import eyeClosed from "../assets/icons/eye_closed.svg";

import style from "./customInput.module.css";
import {Error} from "../../components/Error";

const CustomInput = React.forwardRef((
    {
        title,
        value,
        setValue,
        placeholder,
        className,
        type,
        isPassword = false,
        showPassword = false,
        setShowPassword,
        error,
        autoComplete = "new-password",
        addHelper = false,
        goToNextAction = () => {
        },
        incomingId = "",
        deleteAllSpaces = true,
        disabled = false,
        onBlur = () => {},
        onFocus = () => {},
        required = false,
        errorName = ""
    }, ref
) => {

    const showPasswordHandler = (e) => {
        e.preventDefault();
        setShowPassword(prev => !prev);
    }

    const uppercaseValid = useMemo(() => {
        return /(?=.*[A-Z])/.test(value);
    }, [value])

    const lowercaseValid = useMemo(() => {
        return /(?=.*[a-z])/.test(value);
    }, [value])

    const numberValid = useMemo(() => {
        return /(?=.*[0-9])/.test(value);
    }, [value])

    const symbolValid = useMemo(() => {
        return /(?=.*[\W])/.test(value);
    }, [value])

    const changeValue = (e) => {
        setValue(e.target.value)
    }

    useEffect(() => {
        if (isPassword && ref?.current) {
            ref.current.addEventListener("paste", (e) => {
                e.preventDefault();
            })
            ref.current.addEventListener("copy", (e) => {
                e.preventDefault();
            })
        }
    }, [])

    return (
        <label className={className}>
            <p className={style.form_title}>{title} {required ? <span className={style.red}>*</span> : <></>}</p>
            <div className={style.form_input_container}>
                <input
                    ref={ref || null}
                    id={incomingId}
                    autoComplete={autoComplete}
                    className={`${style.form_input} ${error ? style.error : ""} ${type === "password" ? style.secure : ""}`}
                    value={value}
                    onChange={(e) => {
                        changeValue(e)
                    }}
                    placeholder={placeholder}
                    type={autoComplete === "on" ? type : ""}
                    onKeyDown={e => {
                        if (e.key === " " && deleteAllSpaces) {
                            e.preventDefault();
                        }
                        if (e.key === "Enter") {
                            goToNextAction();
                        }
                    }}
                    disabled={disabled}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
                {isPassword &&
                    <button className={style.pass_eye_container} onClick={showPasswordHandler}>
                        <img className={style.pass_eye} alt="pass_visibility" src={showPassword ? eyeOpen : eyeClosed}/>
                    </button>
                }
                <div style={{
                    position: "absolute"
                }}>
                    <Error error={errorName} />
                </div>
            </div>
            {addHelper ? <div className={style.input_additional_info}>
                {tr("password_should_contain")} <span
                className={`${uppercaseValid ? style.active : style.inactive}`}>{tr("uppercase")}</span>, <span
                className={`${lowercaseValid ? style.active : style.inactive}`}>{tr("lowercase")}</span>, <span
                className={`${symbolValid ? style.active : style.inactive}`}>{tr("special_symbol")}</span>, <span
                className={`${numberValid ? style.active : style.inactive}`}>{tr("number")}</span> {tr("and")} <span
                className={`${value.length >= 8 ? style.active : style.inactive}`}>{tr("min_8_char")}</span>.
            </div> : <></>}
        </label>
    )
});

export default CustomInput;