import {cacheService} from "../services/cacheService";

export const clearCache = () => {
    const language = cacheService.get("Language");
    const id = cacheService.get("AlternateGuest");
    const invitedUser = cacheService.get("invitedUser")
    cacheService.clear();
    cacheService.set("Language", language);
    if (id) cacheService.set("AlternateGuest", id);
    if (invitedUser) cacheService.set("invitedUser", invitedUser);
}
