import {observer} from "mobx-react-lite";
import style from './CreatePasswordPopup.module.scss'
import {unityLayoutStore} from "../../../store/unityLayoutStore";
import {cacheService} from "../../../services/cacheService";
import React, {useContext, useEffect, useRef, useState} from "react";
import apiService from "../../../services/apiServices";
import {tr} from "../../../helpers/translations/tr";
import {DecoratorForRoutionAuthContext} from "../../../Providers/DecoratorForRoutionAuth";
import CustomInput from "../../CustomInput";
import {errorStore} from "../../../store/errorStore";

export const CreatePasswordPopup = observer(() => {
    const {authorization} = useContext(DecoratorForRoutionAuthContext);
    const {userData, setShowPassPopup} = unityLayoutStore
    const {setError} = errorStore
    const [inputType, setInputType] = useState('password');
    const [confirmInputType, setConfirmInputType] = useState('password');
    const RegEx = [/(?=.*[a-z])/, /(?=.*[A-Z])/, /(?=.*[0-9])/, /(?=.*[\W])/]

    const [password, setPassword] = useState("");
    const [passValid, setPassValid] = useState(null);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPassValid, setConfirmPassValid] = useState(null);
    const passRef = useRef();
    const confirmPassRef = useRef();

    const [buttonStatus, setButtonStatus] = useState(false);

    useEffect(() => {
        cacheService.set("Authorization", `Token ${userData.token}`);
    }, [])

    useEffect(() => {
        if (password === confirmPassword && passValid && confirmPassValid) {
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
        }
    }, [passValid, confirmPassValid, password, confirmPassword])


    const confirmPass = async () => {
        if (!buttonStatus) return;
        try {
            await apiService.setUserCreatePassword({
                email: userData.email,
                new_password1: password,
                new_password2: confirmPassword
            })
            setShowPassPopup(false)
            authorization(userData);
        } catch (e) {
            console.log("ERROR: ", e)
        }
    }

    const checkPass = (value) => {
        setPassword(value)
        setPassValid(true)
        if (!confirmPassword.length) {
            setError({key: "password_is_not_equal"}, false, false)
            setConfirmPassValid(null)
        } else if (confirmPassword === value) {
            setError({key: "password_is_not_equal"}, false, false)
            setConfirmPassValid(true)
        } else {
            setError({key: "password_is_not_equal"}, true, false)
            setConfirmPassValid(false)
        }
    }

    const checkConfirmPass = (value) => {
        setConfirmPassword(value)
        setConfirmPassValid(true)
        setError({key: "password_is_not_equal"}, false);
    }

    return (
        <>
            <div className={style.blurredBackgroundPopup}></div>
            <div className={style.popup_container}>
                <div className={style.popup_header}>
                    <div className={style.popup_title}>
                        {tr("create_new_password")}
                    </div>
                </div>
                <div className={style.popup_form}>
                    <div className={style.password_input_block}>
                        <CustomInput
                            ref={passRef}
                            type={inputType}
                            value={password}
                            setValue={checkPass}
                            placeholder={tr("password")}
                            title={tr("new_password")}
                            isPassword={true}
                            showPassword={inputType === 'password'}
                            setShowPassword={() => setInputType(inputType === 'password' ? 'text' : 'password')}
                            addHelper={true}
                            goToNextAction={() => confirmPassRef.current.focus()}
                            required={true}
                            autoComplete={"off"}
                            onBlur={() => {
                                if(!password) setPassValid(null)
                                else if (RegEx.every((regex) => regex.test(password))) {
                                    setPassValid(true)
                                } else {
                                    setPassValid(false)
                                }
                            }}
                            error={passValid === false}
                            incomingId={"pass-1"}
                        />
                    </div>
                    <div className={style.password_input_block}>
                        <CustomInput
                            ref={confirmPassRef}
                            type={confirmInputType}
                            value={confirmPassword}
                            setValue={checkConfirmPass}
                            placeholder={tr("password")}
                            title={tr("confirm_password")}
                            isPassword={true}
                            showPassword={confirmInputType === 'password'}
                            setShowPassword={() => setConfirmInputType(confirmInputType === 'password' ? 'text' : 'password')}
                            goToNextAction={confirmPass}
                            autoComplete={"off"}
                            required={true}
                            onBlur={() => {
                                if (password === confirmPassword && confirmPassValid) {
                                    setError({key: "password_is_not_equal"}, false, false)
                                    setConfirmPassValid(true)
                                } else {
                                    setError({key: "password_is_not_equal"}, true, false)
                                    setConfirmPassValid(false)
                                }
                            }}
                            error={confirmPassValid === false}
                            incomingId={"pass-2"}
                            errorName={"password_is_not_equal"}
                        />
                    </div>
                    <button disabled={!buttonStatus}
                            className={`${style.create_button} ${!buttonStatus ? style.disabled : ""}`}
                            onClick={confirmPass}>
                        {tr("create")}
                    </button>
                </div>
            </div>
        </>
    )
})