import React, {useEffect, useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {Link, useLocation} from "react-router-dom";
import {CerebrumLogoSvg} from "../../ui/assets/svgJs";
import {cacheService} from "../../services/cacheService";
import {copyTextToClipboard} from "../../utils";
import {observer} from "mobx-react-lite";
import useErrorHandler from "../../hooks/useErrorHandler";
import {unityStore} from "../../store/unityStore";
import {translationStore} from "../../store/translationStore";
import {roomStore} from "../../store/roomStore";
import {participantsStore} from "../../store/participantsStore";
import {loadingStore} from "../../store/loadingStore";
import {unityLayoutStore} from "../../store/unityLayoutStore";
import {privateTalkStore} from "../../store/privateTalkStore";
import "./style.scss";
import {useNavigateTo} from "../../helpers/navigateTo";

const Mobile = observer(() => {
    const [copied, setCopied] = useState(false);
    const [roomId, setRoomId] = useState(cacheService.get("RoomId"));
    const [authToken, setAuthToken] = useState(cacheService.getAuthToken());
    const {setIsActiveMobile, setHooryToken, setRoomToken, clearUnityData} = unityStore;
    const { roomName, roomBackground, clearRoomData, creatorName } = roomStore
    const {translationData, activeLanguage} = translationStore;
    const { userIsBlocked, setBlockedUser } = participantsStore
    const {toggleUnityLoading} = loadingStore;
    const {clearUnityLayoutData} = unityLayoutStore;
    const {clearPrivateTalkData} = privateTalkStore;
    const {navigateTo} = useNavigateTo();
    const location = useLocation();
    const [url, setUrl] = useState("");

    useErrorHandler();

    useEffect(() => {
        (async()=>{
            const curAuthToken = cacheService.getAuthToken();
            setRoomId(location.pathname.split("dashboard/")[1])

            if (authToken !== curAuthToken) setAuthToken(curAuthToken);
        })()
    }, []);

    useEffect(() => {
        (async()=>{
            if (userIsBlocked && isMobile) {
                setHooryToken("");
                setRoomToken("");
                toggleUnityLoading(false);
                setBlockedUser(false);
                clearUnityLayoutData();
                clearUnityData();
                clearRoomData();
                clearPrivateTalkData();
                navigateTo("/dashboard", {replace: true});
            }
        })()
    }, [userIsBlocked]);

    const handleCopyLink = () => {
        setCopied(true);

        const url = `${window.location.href}`;
        void copyTextToClipboard(url);

        setTimeout(() => {
            setCopied(false);
        }, 800);
    };

    useMemo(async () => {
        const defaultUrl = "https://pandamr.page.link/?link=";
        const secondaryUrl = "&apn=io.pandamr.metamobile&isi=6443733758&ibi=io.pandamr.metamobile&efr=1";
        const encodedUrl = encodeURIComponent(`https://pandamr.io/dashboard?command=LoginAndOpenRoom&roomId=${roomId}&userToken=${authToken}`);
        // const encodedUrl = encodeURIComponent(`https://pandamr.io/dashboard?stream_accessToken=${token}`); //command=LoginAndOpenRoom&roomId=${roomId}&userToken=${authToken}

        setUrl(String(defaultUrl + encodedUrl + secondaryUrl));
    }, [authToken, roomId]);

    return (
        <div className="event-in-mobile-container">
            <img alt={"Img"} src={roomBackground} className="background"/>
            <div className="event-title-container">
                <div className="event-title-left-container">
                    <Link className="back" to={`/dashboard`} onClick={() => setIsActiveMobile(false)}>
                        <i className="icon-back"/>
                    </Link>
                    <div className="title-container">
                        <p className="title">{roomName}</p>
                        <span>
                            {activeLanguage !== 'ARM' ? (translationData?.['by'] || 'by') : ''} {creatorName} {activeLanguage === 'ARM' ? (translationData?.['by'] || 'ի կողմից') : ''}
                        </span>
                    </div>
                </div>
                <button
                    className="p-mr-event-popover-action tooltip"
                    onClick={handleCopyLink}
                >
                    <span
                        className={`tooltiptext ${copied ? "active" : ""}`}>{translationData?.["copied"] || "Copied!"}</span>
                    <i className="icon-link"/>
                </button>
            </div>
            <div className="open-on-mobile">
                <CerebrumLogoSvg className="logo" />
                <p>{translationData?.["open_application_to"] || "Open application to join this space"}</p>
                <>
                    <a className="open-in-app"
                       href={url}>
                        {translationData?.["open_in_app"] || "Open in app"}
                    </a>
                </>
            </div>
        </div>
    );
});

export default Mobile;